import { createInstance, Identify } from '@amplitude/analytics-browser';
import type { BrowserClient } from '@amplitude/analytics-types';
import { isDevelopment } from '@puzzles/common/utils/is-development';
import { AmplitudeEvent } from './types';

const SHOW_LOGS = true;

let initialized = false;
let amplitude: BrowserClient;

/**
 * Initializes the Amplitude service with the given API Key.
 * Must be called before any other function. Can be called multiple times, but only the first call will have an effect.
 * @param apiKey Amplitud API Key to initialize the service (like "7f23e18c5cb7532001f3602e18c5cb75")
 */
export const initializeAmplitude = (apiKey: string) => {
  if (initialized) return;
  initialized = true;

  log(`initializeAmplitude() with key ${apiKey}`);
  if (isDevelopment()) return;

  try {
    amplitude = createInstance();
    (amplitude as any).Identify = Identify; //needed for AmplitudeForWebGL_SetUserProperty, for Unity functions to use the JS type to set user properties, available in amplitude.Identify as it was in the JS minified library
    amplitude.init(apiKey);
    (window as any).amplitude = amplitude; // For 3rd party libraries expecting amplitude to be in the global scope
  } catch (e) {
    console.error('Amplitude initialization failed', e);
  }
};

/**
 * Calls Amplitude's `track` function with the given event name and properties.
 * @param eventProps Amplitude event data correctly extending from `AmplitudeEvent`.
 * @param eventProps.eventName Target event name (like "Start Multiplayer Session")
 * Be sure to use the same event name and properties as defined in the Amplitude dashboard.
 */
export const trackEvent = <T extends AmplitudeEvent>(eventProps: T) => {
  if (!initialized) {
    console.error('Service not initialized. Initialize it before calling any other function.');
    return;
  }

  const { eventName, ...eventProperties } = eventProps;
  log(`Track "${eventName}":`, eventProperties);
  if (isDevelopment()) return;

  if (!amplitude) {
    console.error('Amplitude instance unavailable.');
    return;
  }
  amplitude.track(eventName, eventProperties);
};

// Just a helper function to log to the console in development mode
const log = (message: string, ...extra: any[]) => {
  if (!isDevelopment() || !SHOW_LOGS) return;

  console.log(`[Amplitude] ${message}`, ...extra);
};
