// DragonBox Web Puzzles API Key
// https://analytics.amplitude.com/kahoot/settings/projects/441937/general
export const AmplitudeApiKey = '9f23e18c5eb7532001f2602606ba7972';

// Events --------------------------------------------------------------------------------------------------------------
// Client:
export const ClientStartMultiplayerSession = 'CLIENT - Start Multiplayer Session';
export const ClientEndMultiplayerSession = 'CLIENT - End Multiplayer Session';

// Host:
export const HostStartMultiplayerSession = 'HOST - Start Multiplayer Session';
export const HostEndMultiplayerSession = 'HOST - End Multiplayer Session';
export const HostMainHubPageView = 'Main HUB Page view';
export const HostReplay = 'HOST - Replay';
export const HostSelectAnotherPlaylist = 'HOST - Select another playlist';
export const HostActivityPageViewSuffix = ' Page view';
export const HostActivityVideoClickedSuffix = ' Video Clicked';
export const HostActivityClickOnPrefix = 'Click on ';
export const HostActivityThumbnailSuffix = ' Thumbnail';

// Activities:
export const StartTutorial = 'Tutorial Start';
export const EndTutorialStep = 'Tutorial Step Completed';
export const EndTutorial = 'Tutorial Finish';
export const EndLevel = 'End Level';

// DuckFractions:
export const DuckFractionsSoloStartSession = 'Start Solo Session';
export const DuckFractionsSoloEndSession = 'End Solo Session';
export const DuckFractionsSoloEndLevel = 'End Level';
export const DuckFractionsSoloPageView = 'Fraction Solo Page View';
export const DuckFractionsSoloClickFractionThumbnail = 'Click Fractions Solo Thumbnail';
